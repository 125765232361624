import { useTranslations } from '@pepita-react/i18n';

import { RealEstateListHeader } from 'src/components/RealEstateListHeader';

import { SortingSelect } from 'src/entities/search/components/SortingSelect';

import { useNumberFormatterCallback } from 'src/hooks/useFormatters';
import { useSortingSelect } from './useSortingSelect';

import css from './styles.module.scss';

interface HeaderProps {
  count: number;
  name: string;
  totalAgencies?: number;
}

export function RealEstateResultsHeader({
  count,
  name,
  totalAgencies,
}: HeaderProps) {
  const { trans } = useTranslations();
  const formatNumber = useNumberFormatterCallback();

  const labelName = totalAgencies
    ? ` ${trans('single_from_agencies_results', {
        params: [formatNumber(totalAgencies, 0)],
        count: totalAgencies,
      })} ${name}:`
    : `: ${name}`;

  const { options, value, handleChange } = useSortingSelect();

  return (
    <RealEstateListHeader
      title={
        <>
          {trans('single_search_result__mobile', {
            params: [formatNumber(count, 0)],
            count,
          })}

          <span className={css['ag-realEstateResultsHeaderName']}>
            {labelName}
          </span>
        </>
      }
    >
      <SortingSelect options={options} value={value} onChange={handleChange} />
    </RealEstateListHeader>
  );
}
