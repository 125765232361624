import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { useAtomicStateAtomSetState } from 'src/atoms/atomic-state';

import type { ListingsSortingOption } from 'src/entities/listing/types/sorting';
import {
  getSelectedOption,
  getSortingOptions,
} from 'src/entities/listing/utils/sorting';

import { useQueryParams } from 'src/hooks/useQueryParams';

import { ensure } from 'src/utils/common';

import { queryParamsStateAtom } from 'src/views/Agency/atoms/queryParamsStateAtom';
import { useUpdateQueryParams } from 'src/views/Agency/hooks/useUpdateQueryParams';

export const useSortingSelect = () => {
  const { trans } = useTranslations();
  const { sortBy, sortDir } = useQueryParams();

  const options = getSortingOptions(
    [
      'moreRelevant',
      'moreExpensive',
      'bigger',
      'moreRooms',
      'newer',
      'lessExpensive',
      'smaller',
      'lessRooms',
      'older',
    ],
    trans
  );

  const [selected, setSelected] = useState<ListingsSortingOption>(() =>
    getSelectedOption(options, sortBy, sortDir, ensure(options.at(0)).value)
  );

  const setQueryParam = useAtomicStateAtomSetState(queryParamsStateAtom);

  useUpdateQueryParams();

  const handleChange = (option: ListingsSortingOption) => {
    setQueryParam((prevState) => ({
      ...prevState,
      sortBy: option.criterion || undefined,
      sortDir: option.direction,
    }));

    setSelected(option);
  };

  return {
    options,
    value: selected,
    handleChange,
  };
};
