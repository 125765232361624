import type { ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

import { Tab } from '../Tab';

export interface TabListProps {
  children: ReactNode;
  selected?: number;
  onSelect?: (index: number, name?: string) => void;
}

export function TabList({ children, selected, onSelect }: TabListProps) {
  return (
    <div className="nd-tabs" role="tablist">
      {Children.map(
        Children.toArray(children).filter(Boolean),
        (child, index) => {
          if (isValidElement(child) && child.type === Tab) {
            return cloneElement(child, {
              ...child.props,
              index,
              selected: index === selected,
              onSelect: () => onSelect && onSelect(index, child.props.name),
            });
          }

          // eslint-disable-next-line no-console
          console.warn('`TabList` children should be of type `Tab`');

          return null;
        }
      )}
    </div>
  );
}
