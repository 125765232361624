import placeholder from '@pepita-fe/sprite-placeholder-manager/sprite.svg';
import type { AgentGender } from 'src/repository/agency/types';

import { LinkOrHtmlTag } from 'src/components/LinkOrHtmlTag';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import css from './styles.module.scss';

interface TeamItemProps {
  gender: AgentGender;
  name: string;
  surname: string;
  thumbnail?: string;
  url?: string;
}

export function TeamItem({
  gender,
  name,
  surname,
  thumbnail,
  url,
}: TeamItemProps) {
  return (
    <LinkOrHtmlTag
      tag="div"
      url={url}
      target="_blank"
      className={css['ag-teamItem']}
    >
      <PepitaFigure
        customClass={css['ag-teamItem__figure']}
        ratio="square"
        bordered
      >
        {thumbnail ? (
          <PepitaFigure.Image src={thumbnail} />
        ) : (
          <PepitaFigure.Content customClass={css['ag-teamItem__placeholder']}>
            <PepitaIcon
              name={`agent_${gender}`}
              spriteUrl={placeholder}
              customClass={css['ag-teamItem__placeholderIcon']}
            />
          </PepitaFigure.Content>
        )}
      </PepitaFigure>

      <p className={css['ag-teamItem__name']}>{`${name} ${surname}`}</p>
    </LinkOrHtmlTag>
  );
}
