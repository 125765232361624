import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import type { FranchisingData } from 'src/repository/agency/types/formatted-data-types';

import { CoverPageMap } from 'src/components/CoverPage/CoverPageMap';
import { CoverTitle } from 'src/components/CoverPage/CoverTitle';
import { LocationLink } from 'src/components/CoverPage/LocationLink';
import { ReadAll } from 'src/components/ReadAll';

import { FranchisingContact } from '../FranchisingContact';

import css from './styles.module.scss';

interface FranchisingOverviewProps {
  data: FranchisingData;
}

export function FranchisingOverview({ data }: FranchisingOverviewProps) {
  const {
    address,
    name,
    website,
    location: { latitude, longitude },
    description,
    keyUrl,
    image,
    phones,
  } = data;
  const { trans } = useTranslations();
  const [openMapDialog, setOpenMapDialog] = useState(false);

  return (
    <div key={keyUrl} className={css['ag-franchisingOverview']}>
      <div className={css['ag-franchisingOverview__top']}>
        <div>
          <CoverTitle title={name} />
          {openMapDialog &&
            address &&
            Boolean(latitude) &&
            Boolean(longitude) && (
              <CoverPageMap
                latitude={latitude}
                longitude={longitude}
                agencyLogo={image}
                address={address}
                title={trans('position_generic', { params: [name] })}
                onClose={() => setOpenMapDialog(false)}
              />
            )}
          <LocationLink
            website={website}
            address={address}
            latitude={latitude}
            longitude={longitude}
            onLocationClick={() => setOpenMapDialog(true)}
          />
        </div>
        <FranchisingContact viewInModal phones={phones} />
      </div>

      {description && (
        <div className={css['ag-franchisingOverview__bottom']}>
          <ReadAll enableToggle>
            <div
              dangerouslySetInnerHTML={{
                __html: description.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
            />
          </ReadAll>
        </div>
      )}
    </div>
  );
}
