import placeholder from '@pepita-fe/sprite-placeholder-manager/sprite.svg';
import clsx from 'clsx';
import type { AgentGender } from 'src/repository/agency/types';
import type { AgencyPhone } from 'src/repository/agency/types/formatted-data-types';

import { CoverPageStickyHeaderWrapper } from 'src/components/CoverPage/CoverPageStickyHeaderWrapper';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { AgentContacts } from '../AgentContacts';

import css from './styles.module.scss';

interface AgentStickyHeaderProps {
  image?: string;
  gender: AgentGender;
  name: string;
  phones: AgencyPhone[];
  url: string;
}

export function AgentStickyHeader({
  image,
  gender,
  name,
  phones,
  url,
}: AgentStickyHeaderProps) {
  return (
    <CoverPageStickyHeaderWrapper customClass={css['ag-agentSticky']}>
      <div className={css['ag-agentSticky__identity']}>
        <PepitaFigure
          ratio="square"
          customClass={clsx(css['ag-agentSticky__figure'])}
          bordered
        >
          {image ? (
            <PepitaFigure.Image src={image} />
          ) : (
            <PepitaFigure.Content>
              <PepitaIcon
                name={`agent_${gender}`}
                spriteUrl={placeholder}
                customClass={css['ag-agentSticky__placeholder']}
              />
            </PepitaFigure.Content>
          )}
        </PepitaFigure>
        <p className={css['ag-agentSticky__name']}>{name}</p>
      </div>
      <AgentContacts phones={phones} url={url} isHorizontal />
    </CoverPageStickyHeaderWrapper>
  );
}
