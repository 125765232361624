import { useEffect, useMemo, useRef } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { agencyRealEstateListQueryKey } from 'src/repository/agency/utils';

import { realEstateListCountAtom } from '../atoms/realEstateListCountAtom';

import { getListingsUserPreferences } from 'src/entities/listing/api';

import { useAgencyQueryParams } from './useAgencyQueryParams';

import { getRealEstateCommercialItemsValue } from 'src/tracking/ga/utils/getRealEstateCommercialItemsValue';
import { updateGA4AgencyTrackingData } from 'src/tracking/ga/utils/store';

import type { ListDetail } from 'src/types/real-estate-list';
import type { RealEstateUserPreferences } from 'src/types/user-preferences';

import { http } from 'src/utils/client/http';
import { findRealEstateByKey } from 'src/utils/real-estate';

const getRealEstateListSaved = (
  listing: ListDetail[],
  preferences: RealEstateUserPreferences[]
) => {
  return listing.map((item) => {
    const preference = findRealEstateByKey(item.realEstate, preferences);

    if (!preference) {
      return item;
    }

    return {
      ...item,
      realEstate: {
        ...item.realEstate,
        saved: preference.saved,
      },
    };
  });
};

export interface RealEstateList {
  listing: ListDetail[];
  count: number;
}

export const useRealEstateList = () => {
  const queryKeyRef = useRef<unknown>();
  const { user } = useAuthContext();
  const { id, pageType, lang, ...searchParams } = useAgencyQueryParams();

  const setRealEstateCount = useSetAtom(realEstateListCountAtom);
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => agencyRealEstateListQueryKey(id, pageType, searchParams, lang),
    [id, pageType, searchParams, lang]
  );

  const { data, isFetched } = useQuery({
    queryKey,
    queryFn: () =>
      http
        .get(`/api-next/agency/${id}/listing/`, {
          searchParams: {
            type: pageType,
            ...searchParams,
            __lang: lang,
          },
        })
        .json<RealEstateList>(),
  });

  useEffect(() => {
    if (!data || user) {
      return;
    }

    updateGA4AgencyTrackingData(
      {
        realEstateIdList: data.listing.map((list) => list.realEstate.id),
        realEstateCommercialItemValue: getRealEstateCommercialItemsValue(
          data.listing.map((listing) => listing.realEstate)
        ),
      },
      true
    );
  }, [data, user]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setRealEstateCount(data.count);
  }, [data, user, setRealEstateCount]);

  useEffect(() => {
    if (
      !user ||
      !isFetched ||
      JSON.stringify(queryKey) === JSON.stringify(queryKeyRef.current)
    ) {
      return;
    }

    queryKeyRef.current = queryKey;

    const cachedListing: Optional<RealEstateList> =
      queryClient.getQueryData(queryKey);

    if (!cachedListing) {
      return;
    }

    const listing = cachedListing.listing
      .filter((listing) => typeof listing.realEstate.saved !== 'boolean')
      .map((item) => ({
        id: item.realEstate.id,
        type: item.realEstate.type,
      }));

    updateGA4AgencyTrackingData(
      {
        realEstateIdList: cachedListing.listing.map(
          (list) => list.realEstate.id
        ),
        realEstateCommercialItemValue: getRealEstateCommercialItemsValue(
          cachedListing.listing.map((listing) => listing.realEstate)
        ),
      },
      true
    );

    getListingsUserPreferences(listing)
      .then((preferences) => {
        const listingSaved = getRealEstateListSaved(
          cachedListing.listing,
          preferences
        );

        queryClient.setQueryData(queryKey, {
          ...cachedListing,
          listing: listingSaved,
        });
      })
      .catch(() => {
        // TODO: manage user preferences error
      });
  }, [queryKey, user, isFetched]);

  return {
    currentPage: typeof searchParams.pag === 'number' ? searchParams.pag : 1,
    data,
  };
};
