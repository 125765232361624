import { useState } from 'react';
import filterSprite from '@pepita-fe/sprite-listing-search/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { useAtom } from 'jotai';

import {
  useAtomicStateAtomSetState,
  useAtomicStateStateAtom,
} from 'src/atoms/atomic-state';

import { ToggleMapListButton } from 'src/components/ToggleMapListButton';

import { showMapAtom } from 'src/entities/search/components/SearchLayout';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';
import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import {
  queryParamsStateAtom,
  searchFormStateAtom,
} from 'src/views/Agency/atoms/queryParamsStateAtom';

import { ContractSelect } from '../ContractSelect';
import { FooterButton } from '../FooterButton';
import { TypologySelect } from '../TypologySelect';

import css from './styles.module.scss';

export function MobileFilters() {
  const { trans } = useTranslations();
  const [openDialog, setOpenDialog] = useState(false);
  const [searchFormState, setSearchFormState] =
    useAtomicStateStateAtom(searchFormStateAtom);
  const setQueryParamsState = useAtomicStateAtomSetState(queryParamsStateAtom);
  const [showMap, setShowMap] = useAtom(showMapAtom);

  const handleResetSearchForm = () => {
    setQueryParamsState({});
  };

  const handleFooterClick = () => {
    setQueryParamsState(searchFormState);
    setOpenDialog(false);
  };

  return (
    <>
      <div className={css['ag-mobileFilters__buttons']}>
        <PepitaButton
          block
          icon={{ name: 'sliders', spriteUrl: filterSprite }}
          onClick={() => setOpenDialog(true)}
          title={trans('act_filter', { capitalize: true })}
        >
          {trans('act_filter', { capitalize: true })}
        </PepitaButton>
        <ToggleMapListButton
          block
          onToggle={(s) => setShowMap(s === 'list' ? true : false)}
          initialState={showMap ? 'list' : 'map'}
        />
      </div>

      {openDialog && (
        <PepitaDialog
          title={trans('act_filter', { capitalize: true })}
          size={PepitaDialogFrameType.FULL}
          content={
            <>
              <PepitaList
                customClass={css['ag-mobileFilters__list']}
                type="borderBottom"
                items={
                  <>
                    <PepitaListItem
                      customClass={css['ag-mobileFilters__listItem']}
                    >
                      <ContractSelect
                        inModal
                        contractId={searchFormState.contractId}
                        onChange={(contractId) =>
                          setSearchFormState((prevState) => ({
                            ...prevState,
                            contractId,
                          }))
                        }
                      />
                    </PepitaListItem>
                    <PepitaListItem
                      customClass={css['ag-mobileFilters__listItem']}
                    >
                      <TypologySelect
                        inModal
                        contractId={searchFormState.contractId}
                        categoryId={searchFormState.categoryId}
                        typeId={searchFormState.typeId}
                        onChange={(categoryId, typeId) =>
                          setSearchFormState((prevState) => ({
                            ...prevState,
                            categoryId,
                            typeId,
                          }))
                        }
                      />
                    </PepitaListItem>
                  </>
                }
              />
            </>
          }
          frameCustomClass={css['ag-searchFormModal__dialog']}
          footer={
            <>
              <PepitaButton
                variant="link"
                customClass={css['ag-searchFormModal__reset']}
                onClick={handleResetSearchForm}
              >
                {trans('act_erase')}
              </PepitaButton>
              <FooterButton onClick={handleFooterClick} />
            </>
          }
          onClose={() => {
            setOpenDialog(false);
          }}
        />
      )}
    </>
  );
}
