import { useEffect, useRef } from 'react';
import type { AgencyQueryParams } from 'src/repository/agency/types';

import { useAgencyQueryParams } from './useAgencyQueryParams';
import { useAgencyTracking } from './useAgencyTracking';
import type { RealEstateList } from './useRealEstateList';

import { deepEqual } from 'src/utils/object';

interface UseAgencyTrackListingShownArgs {
  realEstateList?: RealEstateList;
  count?: number;
  currentPage: number;
  maxPages: number;
  referrer: string;
}

export const useAgencyTrackListingShown = ({
  realEstateList,
  currentPage,
  maxPages,
  referrer,
}: UseAgencyTrackListingShownArgs): void => {
  const queryParams = useAgencyQueryParams();
  const oldParams = useRef<AgencyQueryParams>();
  const { trackingListingsShown } = useAgencyTracking();

  useEffect(() => {
    if (!realEstateList || deepEqual(queryParams, oldParams.current)) {
      return;
    }

    const realEstateListResult = realEstateList.listing.map((item) => ({
      id: item.realEstate.id,
      type: item.realEstate.type,
    }));

    trackingListingsShown({
      currentPage,
      totalPages: maxPages,
      totalResults: realEstateList.count,
      realEstateListResult,
      queryParams,
      referrer,
    });

    oldParams.current = queryParams;
  }, [queryParams]);
};
